import React from "react"
import classNames from "classnames"

import MediaBox from "../components/Elements/MediaBox"
import Hero from "../components/Layout/Hero"
import Container from "../components/Layout/Container"
import Button from "../components/Elements/Button"
import SEO from "components/Layout/SEO"

import career__ops from "../../static/images/career/career__ops.png"
import career__support from "../../static/images/career/career__support.png"
import career__medical from "../../static/images/career/career__medical.png"
import career__plane from "../../static/images/career/career__plane--white-long.png"

import styles from "../../src/components/StaticPages/utils/staticPages.module.scss"

const CareersPage = () => {
  return (
    <>
      <SEO title="Careers" />
      <Hero
        background={{ image: career__plane, position: "center" }}
        color="warning"
        className={classNames("py-3", styles["careers"])}
        size="small"
      >
        <Container isCentered desktop={10} fullhd={8} className="mt-2 mb-2">
          <div className={styles["banner__body"]}>
            <h1>Delivering healthcare everywhere</h1>
            <p className="mt-2 subtitle is-size-7-tablet is-size-7-mobile">
              MedGrocer's technology platforms optimize "medicine-as-a-service"
              for patients and companies. Its ePharmacy, corporate health
              services, and patient programs empower customers to get their
              medicines, vaccines, and medical services conveniently,
              cost-effectively, and intelligently.
            </p>
            <p className="mt-1 subtitle is-size-7-tablet is-size-7-mobile mb-2">
              Beyond just delivering medicines, we deliver healthcare solutions:
              from analytics, to customized programs, to end-to-end fulfillment.
            </p>
            <p className="mt-1 subtitle is-size-7-tablet is-size-7-mobile mb-2">
              In partnership with the country's top employers, health insurers,
              and multinational drug companies, we redefine the medicine
              experience for thousands of patients every day.
            </p>
            <a href="/join">
              <Button color="primary" className={styles["banner__button"]}>
                Join Us
              </Button>
            </a>
          </div>
        </Container>
      </Hero>
      <Hero className="mt-5-desktop mt-5-fullhd">
        <Container
          isCentered
          desktop={10}
          fullhd={10}
          className="mt-5-desktop mt-5-fullhd"
        >
          <div className="content has-text-centered pb-2">
            <h2>Our roles for your goals</h2>
          </div>
          <div className="columns is-centered content has-text-centered">
            <MediaBox
              title="Operations"
              image={{ src: career__ops, alt: "ops" }}
            >
              <ul>
                <li>Product Development</li>
                <li>Business Development</li>
                <li>Supply Chain</li>
              </ul>
            </MediaBox>
            <MediaBox
              title="Support"
              image={{ src: career__support, alt: "support" }}
            >
              <ul>
                <li>Finance</li>
                <li>Human Resources</li>
                <li>Developers</li>
              </ul>
            </MediaBox>
            <MediaBox
              title="Medical"
              image={{ src: career__medical, alt: "medical" }}
            >
              <ul>
                <li>Pharmacists</li>
                <li>Nurses and Doctors</li>
                <li>Psychologists and Psychiatrists</li>
              </ul>
            </MediaBox>
          </div>
        </Container>
      </Hero>
    </>
  )
}

export default CareersPage
